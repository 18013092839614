/*** Headline ***/

.mco-elements
	.mco-view-component-container-extension-bg
	+ .mco-view-component-container-basic-headline {
	margin-top: -40px;
}
.mco-elements
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-text {
	margin-top: -40px;
}
.mco-elements
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-gallery {
	margin-top: -40px;
}
.mco-elements
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-imagetext {
	margin-top: -40px;
}
.mco-elements
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-teaser {
	margin-top: -40px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2 {
	margin-top: -48px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	h2 {
	color: #333333 !important;
	font-size: 24px !important;
	font-weight: 300 !important;
	letter-spacing: 0.05em !important;
	line-height: 32px !important;
	text-transform: unset !important;
}
.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	h2:after {
	display: none !important;
}
.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-text {
	margin-top: -32px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-text {
	margin-top: -32px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3 {
	margin-top: -48px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3
	+ .mco-view-component-container-basic-text {
	margin-top: -32px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3
	h3 {
	font-size: 20px !important;
	font-weight: 400 !important;
	letter-spacing: 0.01em !important;
	line-height: 26px !important;
}
.mco-elements
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-teaser {
	margin-top: -40px;
}
.mco-elements
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-extension-reviews {
	margin-top: -40px;
}
.mco-view-component-container-basic-dropdown-group
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -79px;
}
.mco-view-component-breadcrumb + .mco-view-component-container-basic-headline--1 {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-basic-video {
	margin-top: -48px;
}

/* @media all and (min-width: 1280px) {
	.mco-elements
		.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-text {
		margin-top: -72px;
	}
	.mco-elements
		.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-imagetext {
		margin-top: -72px;
	}
	.mco-elements
		.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-gallery {
		margin-top: -72px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2 {
		margin-top: -88px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3 {
		margin-top: -88px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-text {
		margin-top: -72px;
	}
	.mco-elements
		.mco-view-component-container-basic-headline
		+ .mco-view-component-container-extension-reviews {
		margin-top: -72px;
	}
	.mco-elements
		.mco-view-component-container-basic-headline
		+ .mco-view-component-container-basic-teaser {
		margin-top: -72px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-text {
		margin-top: -72px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3
		+ .mco-view-component-container-basic-text {
		margin-top: -72px;
	}
	.mco-view-component-container-basic-dropdown-group
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -119px;
	}
	.mco-view-component-container-basic-singleteaser:not(.fullscreen)
		+ .mco-view-component-container-basic-singleteaser {
		position: relative;
	}
	.mco-view-component-container-basic-singleteaser:not(.fullscreen)
		+ .mco-view-component-container-basic-singleteaser:before {
		background-image: url("/public/img/mountains.webp");
		background-repeat: no-repeat;
		content: "";
		object-fit: contain;
		height: 180px;
		left: 50%;
		margin-left: 176px;
		position: absolute;
		top: -137px;
		width: 548px;
	}
} */
