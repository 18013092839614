@import url("./fonts.css");
@import url("./custom.css");
@import url("./collaborate.css");

html {
	font-size: 100%;
	min-height: calc(100% + env(safe-area-inset-top));
	/* scrollbar-gutter: stable; */
}
body {
	background-color: #f0eee9 !important;
	margin: 0;
}
a {
	color: #938e87;
	font-weight: 600;
	text-decoration: none;
}
strong {
	font-weight: 600;
}
p {
	margin: 0;
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
#root {
	padding: 0;
	position: relative;
}
.mco-content .mco-container:first-child {
	margin-top: 0;
}
.MuiSkeleton-text {
	transform: none !important;
}
.mco-collapsing + .mco-collapsing {
	margin-top: -80px;
}
.mco-view-component-container-basic-download-group {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.mco-view-component-container-basic-download-group .mco-view-component-container {
	padding-left: 0;
	padding-right: 0;
}
