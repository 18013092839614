/*** Layout ***/

/*** Headline ***/

h1,
h2,
.mco-view-component-container-basic-headline h3 {
	color: #5f5b57 !important;
}
.mco-view-component-container-basic-headline:not(
		.mco-view-component-container-basic-headline--1
	):not(.mco-view-component-container-basic-headline--2):not(
		.mco-view-component-container-basic-headline--3
	)
	> * {
	color: #333333 !important;
}
.mco-view-component-container-basic-headline--1 > .mco-headline,
.mco-view-component-container-basic-headline--2 > .mco-headline {
	position: relative;
}

/*** Text ***/

.mco-view-component-container-basic-text .mco-text-01 {
	text-align: center;
}
.mco-view-component-container-basic-text--grey {
	background: #5f5b57;
	box-shadow: 0 0 0 100vmax #5f5b57;
	clip-path: inset(0 -100vmax);
	padding-bottom: 40px !important;
	padding-top: 40px !important;
	position: relative;
}
.mco-view-component-container-basic-text--grey .mco-text-01 {
	color: #ffffff !important;
}
.mco-view-component-container-basic-text--grey .mco-text-01 a {
	color: #ffffff !important;
	text-decoration: underline !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-text--grey {
		padding-bottom: 64px !important;
		padding-top: 64px !important;
	}
}

/*** List ***/

.mco-list-01__headline {
	color: #333333 !important;
	margin-bottom: 40px !important;
	position: relative;
}
.mco-list-01__list svg {
	margin: 0 12px 0 0 !important;
}

/*** Links ***/

.mco-references-01__headline {
	color: #333333 !important;
	margin-bottom: 40px !important;
	position: relative;
}
.mco-references-01__list svg {
	margin: 0 12px 0 0 !important;
}
.mco-references-01__list {
	gap: 24px !important;
	justify-content: center !important;
}
.mco-references-01__list > div {
	flex-basis: unset !important;
	max-width: none !important;
	padding: 0 !important;
}
.mco-references-01__list > div a {
	align-items: center;
	border: 1px solid #7c8052 !important;
	color: #7c8052 !important;
	display: flex !important;
	height: 48px !important;
	justify-content: center;
	padding: 0 24px !important;
}
.mco-references-01__list > div a:hover {
	border: 1px solid #938e87 !important;
	color: #938e87 !important;
}
.mco-references-01__list > div a p {
	font-size: 16px !important;
	font-weight: 600 !important;
	line-height: 1 !important;
}

/*** Image Text ***/

.mco-imagetext-01__content {
	text-align: center !important;
}
.mco-imagetext-01__subtitle {
	margin-bottom: 8px !important;
}
.mco-imagetext-01__title {
	color: #333333 !important;
}

@media all and (min-width: 960px) {
	.mco-imagetext-01__content {
		justify-content: center !important;
		text-align: left !important;
	}
	.mco-imagetext-01__wrapper--left .mco-imagetext-01__content {
		text-align: right !important;
	}
	.mco-imagetext-01__title {
		text-align: left !important;
	}
	.mco-imagetext-01__wrapper--left .mco-imagetext-01__title {
		text-align: right !important;
	}
}

/*** Dropdown ***/

.mco-dropdown-01 {
	border-bottom: 1px solid #938e87 !important;
	border-top: unset !important;
}
.mco-dropdown-01 .MuiAccordionSummary-content {
	margin-top: 16px !important;
}
.mco-dropdown-01__summary {
	background: #f0eee9 !important;
}
.mco-dropdown-01__summary.Mui-expanded {
	background: none !important;
}

/*** Table ***/

.mco-table-01__headline {
	color: #938e87 !important;
	margin-bottom: 32px !important;
	position: relative;
	text-align: center !important;
}
.mco-table-01__row td {
	box-shadow: 0px -1px 0px 0px #999999 inset;
}
.mco-table-01__row > th {
	background: none !important;
	box-shadow: 0px -1px 0px 0px #999999 inset;
	color: #938e87 !important;
	font-weight: 400 !important;
	padding: 14px 8px !important;
}
.mco-table-01__cell {
	padding: 9px 8px !important;
}
.mco-table-01__cell strong {
	font-weight: 400 !important;
}
.mco-table-01__footer {
	margin-top: 32px !important;
	padding: 0 !important;
}
.mco-table-01__footer p {
	font-size: 16px !important;
	font-weight: 300 !important;
	letter-spacing: 0 !important;
}

/*** Singleimage ***/

.mco-singleimage-01__content {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
	margin: -70px 16px 0 !important;
	max-width: 540px !important;
	padding: 32px !important;
	position: relative;
}
.mco-singleimage-01__content-title {
	font-size: clamp(20px, 8vw, 24px) !important;
	font-weight: 400 !important;
	margin-bottom: 16px !important;
}
.mco-singleimage-01__content-text {
	font-size: 18px !important;
	font-weight: 300 !important;
}

@media all and (min-width: 960px) {
	.mco-singleimage-01__content {
		left: 50%;
		margin: -70px 0 0 -476px !important;
	}
}

/*** Video ***/

.mco-video-02 {
	background: #f0eee9 !important;
}
.mco-video-02__title {
	color: #938e87 !important;
	font-size: clamp(18px, 8vw, 20px) !important;
	font-weight: 400 !important;
	letter-spacing: 0.01em !important;
	line-height: 1.3 !important;
	text-align: left !important;
}

.mco-view-component-container-basic-video--full-width .mco-video-02__content {
	display: none !important;
}
.mco-view-component-container-basic-video--full-width .mco-video-02 > div:first-child {
	max-width: 100% !important;
	flex-basis: 100% !important;
	width: 100% !important;
}

/*** Singleteaser ***/

.mco-view-component-container-basic-singleteaser.fullscreen
	.mco-singleteaser-01
	> a
	.MuiGrid-container {
	display: block !important;
	flex-wrap: unset !important;
}
.mco-singleteaser-01__image {
	flex-basis: unset !important;
	max-width: none !important;
	width: 100%;
}
.mco-view-component-container-basic-singleteaser.fullscreen .mco-singleteaser-01__content {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
	flex-basis: unset !important;
	margin: -70px 16px 0 !important;
	max-width: 540px !important;
	max-width: 540px !important;
	padding: 32px !important;
	position: relative;
}
.mco-view-component-container-basic-singleteaser.fullscreen .mco-singleteaser-01__image {
	flex-basis: unset !important;
	max-width: none !important;
}
.mco-singleteaser-01__content {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
	flex-basis: unset !important;
	margin: -70px 16px 0 !important;
	max-width: 540px !important;
	max-width: 540px !important;
	padding: 32px !important;
	position: relative;
}
.mco-singleteaser-01__title {
	color: #333333 !important;
	margin-bottom: 16px !important;
	margin-top: 0 !important;
}
.mco-singleteaser-01__addition {
	font-size: 16px !important;
	text-transform: unset !important;
}
.mco-singleteaser-01__button button {
	color: #7c8052 !important;
}
.mco-singleteaser-01__button button:hover {
	background: none !important;
	color: #5f5b57 !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-singleteaser.fullscreen .mco-singleteaser-01__content {
		left: 50%;
		margin: -70px 0 0 -476px !important;
	}
	.mco-view-component-container-basic-singleteaser:not(.fullscreen)
		.mco-singleteaser-01
		> a
		.MuiGrid-container {
		display: grid !important;
		grid-template-columns: 1.5fr 90px 1fr !important;
		grid-template-rows: 1fr;
	}
	.mco-view-component-container-basic-singleteaser.right-direction:not(.fullscreen)
		.mco-singleteaser-01
		> a
		.MuiGrid-container {
		grid-template-columns: 1fr 90px 1.5fr !important;
	}
	.mco-view-component-container-basic-singleteaser:not(.fullscreen)
		.mco-singleteaser-01__image {
		grid-column: 1 / 3;
		grid-row: 1 / -1;
	}
	.mco-view-component-container-basic-singleteaser.right-direction:not(.fullscreen)
		.mco-singleteaser-01__image {
		grid-column: 2 / -1 !important;
	}
	.mco-view-component-container-basic-singleteaser:not(.fullscreen)
		.mco-singleteaser-01__content {
		grid-column: 2 / -1;
		grid-row: 1 / -1;
		height: auto !important;
		margin-bottom: auto !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-top: 48px !important;
	}
	.mco-view-component-container-basic-singleteaser.right-direction:not(.fullscreen)
		.mco-singleteaser-01__content {
		grid-column: 1 / 3 !important;
	}
}
@media all and (min-width: 1280px) {
	.mco-singleteaser-01__content {
		padding: 40px !important;
	}
}

/*** Teaser / Teasergroup ***/

.mco-teaser-list-01__headline {
	margin-bottom: 40px !important;
	position: relative;
}
.mco-teaser-list-01__headline svg {
	margin: 0 12px 0 0 !important;
}
.mco-teaser-01 {
	background: none !important;
}
.mco-teaser-01__bottom {
	justify-content: center !important;
}
.mco-teaser-01__bottom button {
	height: 48px !important;
	min-width: 164px !important;
}
.mco-teaser-01__content {
	padding: 16px 16px 0 !important;
}
.mco-teaser-01__title {
	background: #ffffff;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
	color: #5f5b57 !important;
	font-size: 18px !important;
	font-weight: 400 !important;
	margin-bottom: 24px !important;
	margin-top: -52px !important;
	padding: 16px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
}
.mco-view-component-container-basic-teaser {
	padding-right: 0 !important;
}
.mco-view-component-container-basic-teaser .mco-teaser-list-01--slider .swiper-button-next,
.mco-view-component-container-basic-teaser .mco-teaser-list-01--slider .swiper-button-prev {
	align-items: center !important;
	background: rgba(255, 255, 255, 0.8) !important;
	display: flex !important;
	height: 56px !important;
	justify-content: center !important;
	transform: translate(0, calc(-50% - 160px)) !important;
	width: 45px !important;
}
.mco-view-component-container-basic-teaser .mco-teaser-list-01--slider .swiper-button-prev {
	border-radius: 0px 30px 30px 0px;
	left: -16px !important;
}
.mco-view-component-container-basic-teaser .mco-teaser-list-01--slider .swiper-button-next {
	border-radius: 30px 0 0 30px;
	right: 0 !important;
}
.mco-view-component-container-basic-teaser
	.mco-teaser-list-01--slider
	.swiper-button-disabled {
	display: none !important;
}
.mco-view-component-container-basic-teaser .mco-teaser-list-01--slider .swiper-pagination {
	bottom: auto !important;
	left: auto !important;
	margin-top: 48px !important;
	position: relative !important;
}
.mco-view-component-container-basic-teaser
	.mco-teaser-list-01--slider
	.swiper-pagination-bullet {
	background: none !important;
	border: 1px solid #333333 !important;
	border-radius: 50% !important;
	opacity: 1 !important;
}
.mco-view-component-container-basic-teaser
	.mco-teaser-list-01--slider
	.swiper-pagination-bullet-active {
	background: #938e87 !important;
	border: 1px solid #938e87 !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-teaser {
		padding-right: 16px !important;
	}
	.mco-teaser-01 {
		box-shadow: unset;
		padding: 24px 24px 0 !important;
		transition: box-shadow 300ms ease;
	}
	.mco-teaser-01 picture {
		overflow: hidden;
	}
	.mco-teaser-01 img {
		transition: transform 300ms ease;
	}
	.mco-teaser-01:hover img {
		transform: scale(1.1);
	}
	.mco-teaser-01:hover {
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
	}
	.mco-teaser-01__title {
		margin-bottom: 40px !important;
	}
	.mco-teaser-01__bottom button {
		opacity: 0;
		transform: translate(0, 24px);
		transition: opacity 300ms ease;
	}
	.mco-teaser-01:hover .mco-teaser-01__bottom button {
		opacity: 1;
	}
}

/*** Teaser Slider ***/

.mco-view-component-container-basic-teaser--slider-room .mco-teaser-list-01__headline,
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__headline {
	margin-bottom: 40px !important;
}
.mco-view-component-container-basic-teaser--slider-room .mco-teaser-01,
.mco-view-component-container-basic-teaser--slider .mco-teaser-01 {
	box-shadow: none !important;
	padding: 0 !important;
}
.mco-view-component-container-basic-teaser--slider-room .mco-teaser-01__content,
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__content {
	background: #ffffff !important;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24) !important;
	color: #5f5b57 !important;
	margin-bottom: 16px !important;
	margin-left: 16px !important;
	margin-right: 16px !important;
	margin-top: -32px !important;
	padding: 32px 32px 24px !important;
	position: relative;
	text-align: center;
}
.mco-view-component-container-basic-teaser--slider-room .mco-teaser-01__title,
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__title {
	background: none !important;
	box-shadow: none !important;
	color: #5f5b57 !important;
	font-size: 24px !important;
	line-height: 1.4 !important;
	margin-bottom: 16px !important;
	margin-top: 0 !important;
	padding: 0 !important;
	text-align: left !important;
}
.mco-view-component-container-basic-teaser--slider-room .mco-teaser-01__title::after,
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__title::after {
	display: none !important;
}
.mco-view-component-container-basic-teaser--slider-room .mco-teaser-01__text,
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__text {
	text-align: left !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__bottom {
	display: flex !important;
	justify-content: flex-end !important;
	margin-left: auto !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-01__bottom button {
	color: #7c8052 !important;
	height: 32px !important;
	min-width: auto !important;
	opacity: 1 !important;
	transform: unset !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-teaser--slider-room,
	.mco-view-component-container-basic-teaser--slider {
		position: relative;
	}
	.mco-view-component-container-basic-teaser--slider-room
		.mco-teaser-list-01--slider
		.swiper-button-prev,
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01--slider
		.swiper-button-prev {
		left: 0 !important;
	}
}

@media all and (min-width: 1400px) {
	.mco-view-component-container-basic-teaser--slider-room
		.mco-teaser-list-01--slider
		.swiper-button-next,
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01--slider
		.swiper-button-next {
		background: none !important;
		right: -56px !important;
		transform: translate(0, calc(-50% - 28px)) !important;
	}
	.mco-view-component-container-basic-teaser--slider-room
		.mco-teaser-list-01--slider
		.swiper-button-prev,
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01--slider
		.swiper-button-prev {
		background: none !important;
		left: -56px !important;
		transform: translate(0, calc(-50% - 28px)) !important;
	}
	.mco-view-component-container-basic-teaser--slider-room
		.mco-teaser-list-01--slider
		.swiper-button-prev
		svg,
	.mco-view-component-container-basic-teaser--slider-room
		.mco-teaser-list-01--slider
		.swiper-button-next
		svg,
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01--slider
		.swiper-button-prev
		svg,
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01--slider
		.swiper-button-next
		svg {
		width: 75px !important;
	}
	.mco-view-component-container-basic-teaser--slider-room
		.mco-teaser-list-01--slider
		.swiper-button-next
		svg
		path,
	.mco-view-component-container-basic-teaser--slider-room
		.mco-teaser-list-01--slider
		.swiper-button-prev
		svg
		path,
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01--slider
		.swiper-button-prev
		svg
		path,
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01--slider
		.swiper-button-next
		svg
		path {
		fill: #938e87 !important;
	}
}

/*** Icon Teaser ***/

.mco-view-component-container-extension-icon-teaser {
	background: #f8f8f7;
	box-shadow: 0 0 0 100vmax #f8f8f7;
	clip-path: inset(0 -100vmax);
	padding-top: 80px !important;
}
.mco-view-component-container-extension-icon-teaser
	.mco-teaser-list-01--default
	.MuiGrid-container {
	background: #f8f8f7;
	box-shadow: 0 0 0 100vmax #f8f8f7;
	clip-path: inset(0 -100vmax);
	padding-bottom: 80px;
	padding-top: 40px;
}
.mco-view-component-container-extension-icon-teaser .mco-teaser-list-01--default h2 {
	margin-bottom: 0 !important;
}
.mco-view-component-container-extension-icon-teaser .mco-teaser-list-01__swiperRoot {
	background: #f8f8f7;
	box-shadow: 0 0 0 100vmax #f8f8f7;
	clip-path: inset(0 -100vmax);
	padding-bottom: 24px;
	padding-top: 24px;
}
.mco-view-component-container-extension-icon-teaser {
	max-width: 1100px !important;
}
.mco-view-component-container-extension-icon-teaser
	.mco-teaser-list-01--slider
	.swiper-button-disabled {
	display: none !important;
}
.mco-view-component-container-extension-icon-teaser
	.mco-teaser-list-01--slider
	.swiper-button-next {
	right: -12px !important;
}
.mco-view-component-container-extension-icon-teaser
	.mco-teaser-list-01--slider
	.swiper-button-prev {
	left: -12px !important;
}
.mco-view-component-container-extension-icon-teaser .swiper-pagination {
	bottom: auto !important;
	position: relative !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-extension-icon-teaser .mco-teaser-list-01__swiperRoot {
		padding-bottom: 80px;
		padding-top: 80px;
	}
}

/*** Gallery ***/

.mco-gallery-04 .swiper-slide {
	opacity: 0.3;
	transition: opacity 500ms ease;
}
.mco-gallery-04 .swiper-slide-active {
	opacity: 1 !important;
	transition: opacity 500ms ease;
}
.mco-gallery-04-swiper {
	display: flex;
	flex-direction: column-reverse !important;
}
.mco-gallery-04 .swiper-pagination-bullet {
	background: none !important;
	border: 1px solid #ffffff !important;
	opacity: 1 !important;
}
.mco-gallery-04 .swiper-pagination-bullet-active {
	background: #ffffff !important;
	border: 1px solid #ffffff !important;
}

@media all and (min-width: 960px) {
	.mco-gallery-04 .swiper-slide {
		opacity: 0.1;
	}
	.mco-gallery-04-swiper__item {
		overflow: hidden;
	}
	/* .mco-gallery-04-swiper__item img {
		transition: transform 500ms ease;
	}
	.mco-gallery-04-swiper__item:hover img {
		transform: scale(1.1);
	} */
	.mco-gallery-04-swiper-nav__next,
	.mco-gallery-04-swiper-nav__prev {
		background: rgba(0, 0, 0, 0.3) !important;
		border-radius: unset !important;
		height: 64px !important;
		right: 0 !important;
		top: calc(50% - 25px) !important;
		width: 64px !important;
	}
	.mco-gallery-04-swiper-nav__prev {
		left: 0 !important;
	}
	.mco-gallery-04-swiper-nav__prev svg,
	.mco-gallery-04-swiper-nav__next svg {
		width: 64px !important;
	}
	.mco-gallery-04-swiper-nav__prev svg path,
	.mco-gallery-04-swiper-nav__next svg path {
		fill: #ffffff !important;
	}
}
@media all and (min-width: 1280px) {
	/* .mco-gallery-04 .swiper-slide {
		width: 850px !important;
	} */
}

/*** Download ***/

.mco-view-component-container-basic-download-group > div + div {
	margin-top: 2px !important;
}
.mco-view-component-container-basic-download > div {
	background: #ffffff;
	padding: 8px !important;
}
.mco-download-01__title {
	line-height: normal !important;
}
.mco-download-01__caption {
	display: block !important;
	margin-top: 4px !important;
}

/*** Form ***/

.mco-view-component-container-basic-form {
	background: none !important;
}
.mco-form-date,
.mco-form-input,
.mco-form-select,
.mco-form-textarea {
	background: #ffffff !important;
}
.mco-form-button-wrapper {
	flex: unset !important;
	margin-left: auto !important;
}
.mco-form-button-wrapper button {
	min-width: 150px;
}

/*** Room List ***/

.mco-view-component-container-tourism-room-list {
	position: relative;
}
.mco-products-list-filter {
	justify-content: center !important;
}
.mco-products-list-filter .mco-products-list-filter__categories-container {
	align-items: center !important;
	flex-direction: column;
	gap: 16px !important;
	justify-content: center !important;
}
.mco-products-list-filter .mco-products-list-filter__categories-tab {
	background: none !important;
	border: 1px solid #5f5b57 !important;
	color: #5f5b57 !important;
	font-size: 18px !important;
	font-weight: 600 !important;
	letter-spacing: 0.4px !important;
	min-width: 200px !important;
}
.mco-products-list-filter .mco-products-list-filter__categories-tab--selected {
	background: #5f5b57 !important;
	color: #ffffff !important;
}
.mco-products-list-filter .mco-products-list-filter__categories .MuiTabs-indicator {
	display: none !important;
}
.mco-teaser-list-product .mco-teaser-list-product__content {
	padding-top: 24px !important;
}
.mco-teaser-list-product .mco-teaser-list-product__title {
	font-size: clamp(24px, 8vw, 30px) !important;
	font-weight: 300 !important;
	letter-spacing: 0.1em !important;
	order: 1;
	text-transform: uppercase;
}
.mco-teaser-list-product .mco-teaser-list-product__description {
	margin: 0 0 24px !important;
	order: 2;
}
.mco-teaser-list-product .mco-teaser-list-product__attributes {
	margin: 0 0 24px !important;
	order: 3;
}
.mco-teaser-list-product .mco-teaser-list-product__button-group {
	order: 4;
}
.mco-teaser-list-product .mco-teaser-list-product__buttons button {
	border-color: #7c8052 !important;
	color: #7c8052 !important;
	font-size: 18px !important;
	font-weight: 600 !important;
	letter-spacing: 0.4px !important;
}
.mco-teaser-list-product .mco-teaser-list-product__attributes p,
.mco-teaser-list-product .mco-teaser-list-product__topAttributes p {
	font-family: "Bellota Text" !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	text-transform: uppercase !important;
}
.mco-teaser-list-product__image .swiper-pagination-bullet {
	margin: 0 8px !important;
	opacity: 1 !important;
}
.mco-teaser-list-product__image
	.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
	background: none !important;
	border: 1px solid #ffffff !important;
}

@media all and (min-width: 960px) {
	.mco-products-list-filter .mco-products-list-filter__categories-container {
		flex-direction: row !important;
		margin-bottom: 100px !important;
	}
	.mco-products-list-filter .mco-products-list-filter__categories-tab {
		min-width: auto !important;
		padding: 6px 24px !important;
	}
	.mco-teaser-list-product {
		gap: 56px !important;
	}
	.mco-teaser-list-product .mco-teaser-list-product__content {
		padding-top: 0 !important;
	}
	.mco-teaser-list-product .mco-teaser-list-product__title {
		margin-bottom: 32px !important;
	}
	.mco-teaser-list-product .mco-teaser-list-product__description {
		margin-bottom: 32px !important;
	}
	.mco-teaser-list-product .mco-teaser-list-product__attributes {
		margin-bottom: 32px !important;
	}
	.mco-teaser-list-product .mco-teaser-list-product__button-group {
		max-width: 320px !important;
	}
}

/*** Room Detail ***/

.mco-product-detail .mco-headline,
.mco-product-detail .mco-product-detail-rates .mco-table-01__headline {
	color: #5f5b57 !important;
}
.mco-product-detail .mco-product-detail-facts {
	justify-content: center !important;
	margin-top: -24px !important;
}
.mco-product-detail .mco-product-detail-description .mco-text-01 ul li {
	text-align: left !important;
}
.mco-product-detail .mco-product-detail-description .mco-text-01 {
	text-align: center !important;
}
.mco-product-detail .mco-product-detail-attributes__item svg path {
	fill: #938e87 !important;
}
.mco-product-detail .mco-product-detail-buttons,
.mco-product-detail .mco-product-detail-buttons button {
	background: #afb474 !important;
}
.mco-product-detail .mco-product-detail-buttons button:hover {
	opacity: 0.8 !important;
}
.mco-product-detail .mco-product-detail-buttons__separator {
	height: 35px !important;
	margin: auto 0 !important;
}

@media all and (min-width: 960px) {
	.mco-product-detail .mco-product-detail-attributes__headline,
	.mco-product-detail .mco-product-detail-rates .mco-table-01__headline {
		margin-bottom: 56px !important;
	}
	.mco-product-detail .mco-product-detail-attributes ul {
		grid-template-columns: repeat(2, 1fr) !important;
	}
}

/*** Overlay ***/

.mco-overlay-arrow-next svg,
.mco-overlay-arrow-prev svg {
	width: 75px !important;
}

/*** Recpatcha Badage ***/

.grecaptcha-badge {
	z-index: 1;
}

/*** Interaktionslayer ***/

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	align-items: center !important;
	background: #afb474 !important;
	border: 0 !important;
	border-radius: 0 !important;
	box-shadow: none !important;
	color: #ffffff !important;
	cursor: pointer !important;
	display: flex !important;
	font-size: 18px !important;
	font-weight: 400 !important;
	height: 48px !important;
	letter-spacing: 0.4px !important;
	line-height: 1 !important;
	margin-left: auto !important;
	min-width: 64px !important;
	padding: 8px 24px !important;
	transition: background 300ms ease !important;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: #7c8052 !important;
}
.micado-overlay__close,
.micado-ial__close {
	background: #afb474 !important;
}
.micado-overlay__close:hover,
.micado-ial__close:hover {
	background: #afb474 !important;
}

/*** SEEKDA ***/

:root {
	/* Farben für Anfrage- und Buchenbuttons */
	--hapi-background-button: #a5744a;
	/* Primär: Farben für eine buchungsspezifische Auswahl, z. B. Urlaubszeitraum */
	--hapi-background-primary: #968977;
	/* Ecken für Buttons, Cards usw. */
	--hapi-border-radius: 0px; /* für CIs mit Eckigen Kanten = 0px */
}
